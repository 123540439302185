import { Link, animateScroll as scroll } from 'react-scroll';

function Footer() {

  return (
    <footer>
      <div className='container'>
        <div className='phone'>
          <div className='title'>
            Phone
          </div>
          <div className='number'>
            09969555109
          </div>
        </div>
      </div>
      <div className='bottom'>
        Copyright © 2023. All Rights Reserved. Design By <a href='#'>XH Express</a>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>
    </footer>
  );
}
export default Footer;
