function Header() {
  return (
    <header>
      <img alt='bg' src='/bg.png' className="bgimg" />
      <div className="banner">
        <div className="left">
          <div className="title">
           {`China `}
            <i class="fas fa-solid fa-arrow-right"></i>
           {` Myanmar Express`}
          </div>
          <div className="description">
            XH Express is the Fastest and most reliable parcel delivering app for everyone. You stay at home. We run for you. Believe us we serve the best and reliable services for you.
          </div>
          <div className="app-links">
            <a href="https://apps.apple.com/us/app/xh-express/id6444356524" target={'_blank'} rel="noreferrer">
              <img alt="appstore" src="/img/appstore.png" />
            </a>
            <a href="https://xhexpressapk.s3.ap-southeast-1.amazonaws.com/xhexpress.apk" target={'_blank'} rel="noreferrer">
              <img alt="apk" src="/img/apkfile.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.orikino.xhlexpress" target={'_blank'} rel="noreferrer">
              <img alt="playstore" src="/img/playstore.png" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
