import { Link } from "react-scroll";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 90) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }

  return (
    <nav className="navbar">
      <div className="row">
        <div className="logo">
          <Link
            spy={true}
            smooth={true}
            duration={1000}
            to="headerbg"
            style={{ cursor: "pointer" }}
          >
            <div className="logo-wrapper">
              <img alt='logo' src='/img/logo.png' />
              <div className="name">
                XH Express
              </div>
            </div>
          </Link>
        </div>
        <div className="top-social">
          <div>Follow Us On</div>
          <img alt="fbimg" src={'/img/facebook.svg'} />
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
